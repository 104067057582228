<template>
  <div class="columns">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-6">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-card class="w-100 mb-3 no-gradient-block-1">
              <v-card-text>
                  <div class="pa-3">
                      <v-row>
                        <v-col class="pa-0">
                          <h1 class="title is-6 ml-3">{{  dateText(order.time) }}</h1>
                        </v-col>
                        <v-col class="pa-0 tags_col">
                          <b-taglist>
                            <b-tag rounded v-if="order.status === 'created'" type="is-primary">Создан</b-tag>
                            <b-tag rounded v-else-if="order.status === 'agreed'" type="is-info">Подтвержден</b-tag>
                            <b-tag rounded v-else-if="order.status === 'completed'" type="is-success">Доставлен</b-tag>
                            <b-tag rounded v-else-if="order.status === 'in_progress'" type="is-warning">В пути</b-tag>
                            <b-tag rounded v-else-if="order.status === 'cancelled'" type="is-danger">Отменен</b-tag>
                          </b-taglist>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2 mb-3">
                          <v-col cols="2" class="column is-3">
                              <img src="/assets/road.svg">
                          </v-col>
                          <v-col cols="10" class="column is-9 order-route">
                              <v-row>
                                  <h1 class="title is-5">{{ order.from_location }}</h1>
                              </v-row>
                              <v-row>
                                  <h1 class="title is-5 mt-2">{{ order.to_location }}</h1>
                              </v-row>
                          </v-col>
                      </v-row>

                      <div v-if="order.car_id">
                        <h3 class="title pt-2 is-6">Машина</h3>
                        <p>{{ wrapperCar() }}</p>
                      </div>

                      <h3 class="title is-6">Комментарий</h3>
                      <p class="text-pre-wrap">{{ order.comment }}</p>


                  </div>
              </v-card-text>
          </v-card>

        </v-container>
      </v-main>
    </div>
  </div>

</template>

<script>

export default {
  name: 'OrderInfo',
  data() {
    return {
    };
  },
  created() {
    if (this.orderId == null) {
      this.$router.push('/activity-feed');
    }
    this.$store.dispatch('GET_CURRENT_ORDER', this.orderId);
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    order() {
      return this.$store.getters.CURRENT_ORDER;
    }
  },
  methods: {
    wrapperCar() {
      if (this.order.car.mark && this.order.car.model) {
       return this.order.car.mark + '' + this.order.car.model;
      } else {
        return '';
      }
    },
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
    goBack() {
      this.$router.push('/orders');
    }
  },
};
</script>

<style scope>
.title.is-6 {
  font-size: 1.4em !important;
  margin-bottom: 0 !important;
}

p {
  font-size: 19px;
}

.width-img-50 {
  max-width: 50%; /* Задаем максимальную ширину в 50% */
  margin: 0 auto; /* Центрируем изображение горизонтально */
}
</style>
